<template>
  <div class="coupon-details-wrapper flex">
    <d-search class="d-search"
              :searchData="searchData"
              @searchEvent="search(1)" @exportEvent="exportEvent" @resetEvent="resetSearch"/>
    <div class="inline-flex contentBox">
      <div class="total">
        <p>共计{{countName}}数量: {{totalCount}} 张</p>
        <p>共计{{moneyName}}金额: {{totalMoney}} 元</p>
      </div>
      <d-table :columnData="columnData"
               class="contentTable"
               :tableData="tableData"></d-table>
      <d-paging :pager="pager"
                style="marginTop:50px;"
                @change="search" />
    </div>
  </div>
</template>

<script>
import DSearch from "@/components/d-search";
import DTable from "@/components/d-table";
import DPaging from "@/components/d-paging";
import {onDownload} from "@/utils/utils";
import {deepClone} from "@/components/dynamicForm/utils/utils";
import validateTime from "@/mixins/validateTime";

export default {
  name: "coupon-verification-summary",
  components: {
    DPaging,
    DTable,
    DSearch
  },
  mixins:[validateTime],
  data(){
    return {
      totalCount:0,
      totalMoney:0.00,
      countName: '核销',
      moneyName: '优惠',
      pager: {
        count: 0,
        page: 1,
        rows: 10
      },
      searchData: {
        searchItem: [
          {
            label: '店铺',
            value: 'shopId',
            type: 'linkageSelect',
            options: [],
            filterable:true,
            optionProps: {
              value: 'name',
              label: 'name',
              emitPath: false,
              children: 'childList'
            }
          },
          {
            label: '券名称',
            value: 'couponName',
            type: 'commonInput',
            dateType: 'month',
            placeholder: '请输入券名称'
          },
          {
            label: "券类型",
            value: "couponType",
            type: "commonSelect",
            placeholder: "",
            options: this.$map.getter('copounType')
          },
          {
            label: '状态',
            value: 'type',
            type: 'commonSelect',
            placeholder: '',
            clearable:false,
            options: this.$map.getter('copounStatus'),
          },
          {
            label: '核销时间',
            value: 'time',
            type: 'commonDatetime',
            startPlaceholder: "开始日期",
            endPlaceholder: "结束日期"
          },
        ],
        searchBtn: {
          queryBtn: true,
          exportBtn:true,
          resetBtn: true
        },
        params: {
          couponName: '',
          couponType: '',
          shopId: '',
          type: '1',
          time:['','']
        }
      },
      columnData: [
        { prop: "shop", label: "核销店铺" },
        { prop: "couponTypeName", label: "券类型" },
        { prop: "couponName", label: "券名称" },
        { prop: "count", label: "数量" },
        { prop: "orderAmount", label: "订单金额" },
        { prop: "amountDiscount", label: "优惠金额" },
        { prop: "typeName", label: "状态" }
      ],
      tableData: []
    }
  },
  watch:{
    'searchData.params.type'(val){
      this.countName = val == 1 ?'核销':'退券';
      this.moneyName = val == 1 ?'优惠':'退券'
    }
  },
  mounted(){
    this.searchData.params.time = this.getTimeRange('',7,new Date());;
    this.getShopList();
    this.search();
  },
  methods:{
    // 查询
    search(page){
      page && (this.pager.page = 1,this.pager.rows = 10)
      let requestParam = this.getParams();
      if(!this.validateOneYear(requestParam.startTime,requestParam.endTime)) return;
      this.$api.mentapi.couponSummaryList(requestParam).then(res=>{
        if (res.code === 200) {
          this.pager.count = res.data.total
          this.tableData = res.data.list
        }
      })
      this.getTotal();
    },

    getTotal(){
      let param = this.getParams();
      delete param.pageSize;
      delete param.pageNum;
      this.$api.mentapi.couponTotal(this.getParams()).then(({data})=>{
        this.totalCount = data.count;
        this.totalMoney = Number(data.price).toFixed(2);
      })
    },

    exportEvent(){
      let requestParam = Object.assign(this.getParams(),{pageNum:1,pageSize:10000})
      if(!this.validateOneYear(requestParam.startTime,requestParam.endTime)) return;
      this.$api.mentapi.couponSummaryExport(Object.assign(this.getParams(),{pageNum:1,pageSize:10000})).then(({headers,data})=>{
        onDownload(headers,data)
      })
    },

    /**
     * 获取选择店铺列表
     */
    getShopList () {
      this.$api.shopapi.getShopList()
          .then(({ data, code }) => {
            if (code == 200) {
              this.childList = data.childList
              const child = [{
                id: data.id,
                name: data.name,
                childList: data.childList
              }]
              this.setFilterOption('shopId','options',child)
            }
          })
    },
  getShopId(arr){
      arr.forEach(item=>{
      if(item.childList){
        this.getShopId(item.childList)
      }else{
        if(this.searchData.params.shopId == item.name){
          this.getMyShopId = item.id
        }
      }
      })
      
    },
    getParams(){
      const params = deepClone(this.searchData.params)
      let startTime = (params.time&&params.time[0])||'';
      let endTime = (params.time&&params.time[1])||''
      this.childList&&this.getShopId(this.childList)
      delete params.time;
      return {
        ...params,
        shopId: this.searchData.params.shopId ? this.getMyShopId : null,
        ...{
          pageNum: this.pager.page,
          pageSize: this.pager.rows,
          startTime,
          endTime
        }
      }
    },

    setFilterOption(key,propName,value){
      this.searchData.searchItem.forEach(ele => {
        if (ele.value === key) {
          ele[propName] = value
        }
      })
    },

    resetSearch(){
      this.searchData.params.type = '1';
      this.searchData.params.time = this.getTimeRange('',7,new Date());;
      this.search()
    }
  }
}
</script>

<style lang="scss" scoped>
.total{
  text-align: right;
  margin-bottom: 10px;
  padding: 0 35px;
  line-height: 1.2;
}
</style>